.our-team .icon {
    max-width: 64px;
    filter: brightness(0) saturate(100%) invert(51%) sepia(30%) saturate(860%) hue-rotate(140deg) brightness(94%) contrast(91%);
}


.our-team .image-column .inner-column {
    position: relative;
    padding-top: 90px;
    padding-right: 50px;
    /* margin-left: -320px; */
    /* margin-left: 50px; */
}

.our-team .image-column .image {
    position: relative;
}

.our-team .dark-layer {
    position: absolute;
    right: 120px;
    top: -100px;
    width: 500%;
    bottom: 100px;
    background-color: #374a56;
}

.our-team .image-column .image img {
    position: relative;
    width: 100%;
    display: block;
}

.our-team .card img{
    max-width: 100%;
}

.card-style2:hover .card-body {
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color:rgb(19 143 166 / 29%);
    opacity: 1;
}

.card-style2 .card-body {
    position: absolute;
    top: 0;
    /* bottom: 0; */
    left: 0;
    right: 0;
   height: 100%;
    padding: 36px 30px;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    border-radius: 10px;
}

.card-style2 .card-body .team-data{
    position: absolute;
    top: 20px;
    text-align: start;
}