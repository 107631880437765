.top-position1 {
    margin-top: -96px;
}

.page-title-section {
    padding: 190px 0 100px;
}

.page-title span {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 2px;
    color: #44cadf9c !important
}

.page-title h1 {
    font-weight: 700;
    letter-spacing: 2px;
}

.cover-background {
    position: relative !important;
    background-size: cover !important;
    overflow: hidden !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.dark-overlay[data-overlay-dark]:before {
    background: #142F43;
}

.breadcrumb {
    --bs-breadcrumb-padding-x: 0;
    --bs-breadcrumb-padding-y: 0;
    --bs-breadcrumb-margin-bottom: 1rem;
    --bs-breadcrumb-bg: ;
    --bs-breadcrumb-border-radius: ;
    --bs-breadcrumb-divider-color: var(--bs-color-accent50);
    --bs-breadcrumb-item-padding-x: 0.5rem;
    --bs-breadcrumb-item-active-color: var(--bs-color-accent50);
    --bs-breadcrumb-font-size: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
    margin-bottom: var(--bs-breadcrumb-margin-bottom);
    font-size: var(--bs-breadcrumb-font-size);
    list-style: none;
    background-color: var(--bs-breadcrumb-bg);
    border-radius: var(--bs-breadcrumb-border-radius);
}

.page-title-section .breadcrumb ul li:last-child a {
    color: var(--bs-color-accent50);
    pointer-events: none;
}

.page-title-section .breadcrumb ul li a {
    color: #fff;
    padding: 0 10px;
    text-decoration: unset;

}

.breadcrumb ul li:last-child:before {
    content: "";
    width: 9px;
    height: 9px;
    background-color: var(--bs-color-accent50);
    margin: 0px 1px 0 2px;
    display: inline-block;
    border-radius: 2px;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}