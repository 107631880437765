footer {
    padding: 80px 0 0;
}


.receive-contact {
    background-color: #1191a6;
}

.receive-contact .fa-paper-plane {
    font-size: 18px;
    padding-right: 15px;
}

.contact-info {
    border-radius: 10px;
}

.contact-info i {
    font-size: 24px;
}

.contact-info a {
    text-decoration: unset;
    color: #44cadf;
}

.footer-logo img {
    width: 50%;
}

.links {
    padding-left: .5rem !important;
}


.footer-list li a {
    text-decoration: none;
    color: #64a19d;
}

.footer-list li a:hover {
    text-decoration: none;
    color: #3a8f96;
}

@media (min-width: 576px) {
    .links {
        padding-left: 1rem !important;
    }
}

@media (min-width: 992px) {
    .links {
        padding-left: 5.5rem !important;
    }
}

@media (min-width:1199px) {
    .contact-info {
        margin-top: -30%;
    }
}

.footer-bar {
    padding: 1em;
    text-align: center;
    border-top: 1px solid #80808078;
}