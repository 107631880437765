.main-top {
    min-height: 800px;
    /* background-image: linear-gradient(116deg, #0535458f 55%, #5f9fad99 55%, #5f9fad94 75%, rgb(39 106 123) 75%, rgb(38 104 121)), linear-gradient(61deg, #168fa782, #168fa7c9 55%, #5f9fad99 55%, #5f9fad94 100%, rgb(5 53 69 / 62%) 75%, #053545), url(../../public/assets/images/view-modern-construction-site\ \(1\).jpg); */
    background-image: linear-gradient(116deg, #0535458f 55%, #1476978f 55%), url(../../public/assets/images/view-modern-construction-site\ \(1\).jpg);
    
    /* background-position-x: 10px;
    background-position: 0% 70%; */
    background-size: cover;
}

.main-banner {
    min-height: 75vh !important;
    padding: 1em;
    padding-top: 17em;
}

.main-banner h1 {
    font-size: 5vh;
    letter-spacing: 2px;
    white-space: normal;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    /* text-align: left; */
}

.title-sm {
    font-weight: 400;
    position: relative;
    z-index: 2;
    display: inline-block;
}

/* .title-sm:before {
    content: '';
    background: #1191a6;
    height: 5px;
    width: 100%;
    position: absolute;
    left: -3px;
    bottom: 8px;
    z-index: -1;
} */

.main-banner span {
    font-weight: 600;
    letter-spacing: 4px;
    font-size: 16px;
}

/* 


.btn-learn:hover {
    opacity: 1;
    /* background-color: var(--bs-color-contrast50); 
} */


.btn-learn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    overflow: hidden;
    vertical-align: middle;
    font-size: 14px !important;
    color: #fff !important;
    border-radius: 5px;
    font-weight: 600;
    text-align: center;
    padding: 15px 32px !important;
    text-decoration: unset;
    text-transform: uppercase;
    z-index: 1;
    transition: all 500ms ease;
    background-color: #12414f;
    opacity: .7;
    letter-spacing: 1.6px;
    border: none;
    margin-top: 3em;
    box-shadow: none;
}


.btn-learn:hover {
    color: #e6e6e6;
}

.btn-learn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s, border 0.3s;
}

.btn-learn:hover::before {
    opacity: 0;
}

.btn-learn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 200px;
    background-color: #1191a6ba;
    border-color: transparent;
    border-radius: 50%;
    transform: translate(-10px, -70px) scale(0.1);
    opacity: 0;
    z-index: -1;
    transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}

.btn-learn:hover::after {
    opacity: 1;
    transform-origin: 100px 100px;
    transform: scale(1) translate(-10px, -70px);
}



@media (min-width: 275px) {
    /* .main-banner h1 {
        text-align: center;
    } */

    .title-sm {
        margin: .1em;
        padding: 0 .2em;
    }

    .main-top {
        padding-top: 2em;
    }

    .main-banner h1 {
        font-size: 30px !important;
        text-align: center;
    }

  
    /* .title-sm:before {
        left: 4px;
    } */
}

@media (min-width:576) {
    .main-banner h1 {
        font-size: 30px !important;
    }
}

@media (min-width: 992px) {
    .main-top {
        padding: 0;
        /* background-image: linear-gradient(116deg, #0535458f 55%, #5f9fad99 55%, #5f9fad94 75%, rgba(39, 106, 123, 0.011) 75%, rgba(38, 105, 121, 0)), linear-gradient(61deg, #168fa782, #168fa7c9 55%, #5f9fad99 55%, #5f9fad94 100%, rgb(5 53 69 / 62%) 75%, #05354500), url(../../public/assets/images/view-modern-construction-site\ \(1\).jpg); */
        background-image: linear-gradient(116deg, #0535458f 55%, #5f9fadc5 55%), url(../../public/assets/images/view-modern-construction-site\ \(1\).jpg);
        /* background-image: linear-gradient(120deg, #168fa700, #168fa700 55%, #5f9fad99 55%, #5f9fad94 75%, rgb(5 53 69 / 62%) 75%, rgb(5 53 69)), linear-gradient(61deg, #168fa700, #168fa7c9 55%, #5f9fad99 55%, #5f9fad94 100%, rgb(5 53 69 / 62%) 70%, rgb(5 53 69)), url(../../public/assets/images/background.png); */
        background-size: cover;
    }

    .main-banner h1 {
        font-size: 38px !important;
    }
}

/* SECTION ABOUT */
.image-about {
    position: relative;
    min-height: 460px;

    /* background-image:  linear-gradient(61deg, #168fa700, #168fa7c9 55%, #5f9fad99 55%, #5f9fad94 100%, rgb(5 53 69 / 62%) 70%, rgb(5 53 69)), url(../../public/assets/images/about.jpg); */
    background-size: cover;

}

.image-about::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    color: #FFF;
    box-shadow: inset 0 0 96px #1d4b5a78;
}

.image-about:hover .after {
    display: block;

}

.image-about img {
    width: 100%;
}

.image-about-1 {
    display: none;
    background-image: url(../../public/assets/images/view-modern-construction-site.jpg);
}

.image-about-2 {
    background-image: url(../../public/assets/images/info-23.jpg);

}

@media (min-width: 992px) {
    .image-about-1 {
        display: block;
    }
}


.about-icon {
    /* background-color: var(--bs-color-accent100); */
    border-bottom: 2px solid var(--bs-color-accent100);
    min-width: 60px;
}

.about-icon i {
    color: white;
    font-size: 36px;
    padding: 10px;
    color: var(--bs-color-accent100);

    border-radius: 50%;
}

.about-area {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    padding: 6px;
}


@media (min-width:992px) {
    .about-area:nth-child(2) {
        transform: translateX(120px);
        z-index: 1;
    }
}

@media (min-width:768px) {
    .about-icon {
        /* background-color: var(--bs-color-accent100); */
        border-bottom: unset;
        border-right: 3px solid var(--bs-color-accent100);
        min-width: 60px;
    }

}

/* SECTION SERVICES */

.services-area .service-body {
    border-radius: 10px;
    overflow: hidden;
    background-color: #dbeef27a;
}

.services-area .menu-container {
    margin: 0;
    padding: 0;
}

.services-area .menu {
    height: 100%;
    /* padding-top: 60px; */
    padding-bottom: 60px;
}



.services-area .menu .thumb {
    color: #fff;
    font-size: 12px;
    background-color: #374a56;
    margin: 1px;
    min-height: 40px;
    height: 100%;
    text-align: center;
    padding: 0px 15px;
    cursor: pointer;
    position: relative;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.services-area .menu div:not(:last-child) {
    border-bottom: 1px solid #fff;
}

.services-area .menu .thumb p {
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
}

.services-area .menu .thumb:hover {
    background-color: #12414f;
}

.services-area .menu .thumb:hover p {
    color: #44cadf9c;
}




.services-area .right-image {
    width: 100%;
    display: inline-flex;
}

.services-area img {
    border-radius: 7px;
    width: 100%;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.services-area .main-white-button {
    background-color: #12414f;
    padding: 10px;
    border-radius: 10px;
    margin-top: 4em;
    text-align: center;
    transition: .1s linear;
}

.services-area .main-white-button:hover {
    background-color: #1191a6ba;
}


.services-area .main-white-button a {
    color: white;
    text-decoration: none;
}

@media (min-width:375) {}

@media (min-width:576) {
    .services-area .menu .thumb {
        font-size: 12px;
    }

}

@media (min-width:768px) {
    .services-area .menu .thumb {
        font-size: 12px;
        /* height: 42px;
        line-height: 42px; */
    }

    .services-area .right-image {
        width: 75%;
    }

}

@media (min-width:992px) {

    .services-area .menu {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .services-area .menu>div {
        flex-grow: 1;
        height: 10%;
    }


    .services-area .menu .thumb {
        height: 100%;
    }

    .services-area .right-image {
        padding: 0px;
    }

    .services-area .left-text {
        margin-left: 30px;
        text-align: start;
        padding-left: 10px;
    }

    .services-area .left-text h4 {
        /* color: #fff; */
        font-size: 20px;
        font-weight: 700;
        /* line-height: 35px; */
        margin-bottom: 30px;
        border-bottom: 1px solid #80808073;
        padding-bottom: 1em;
    }

    .services-area .left-text p {
        /* color: #fff; */
        margin-bottom: 30px;
    }

    .services-area .right-image {
        width: 100%;
    }
}

@media (min-width:1200px) {

    /* .services-area .menu {
        padding-top: 40px;
        padding-bottom: 40px;
    } */

    .services-area .menu .thumb {
        font-size: 13px;
        /* height: 60px; */
        /* line-height: 30px; */
    }

    .services-area .right-image {
        padding: 40px;
    }
}


.widget {
    /* background-color: #12414f; */
    /* border: 1px solid #374a56; */
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 5px;
    /* padding: 35px 30px; */
    position: relative;
}

.widget .card{
    overflow: hidden;
    cursor: pointer;
    border-color: transparent;
    background-color: transparent;
}

.widget .card-body{
    padding: 0;
}

.widget .card-title{
    font-size: 10px;
    font-weight: 800;
    padding: 2px;
    color: #000000d6;
}

.widget .card:hover .card-img-top{
    /* transform: scale(1.1); */
    filter: grayscale(0);
}
.widget .card-img-top{
    object-fit: fill;
    height: 100%;
    filter: grayscale(.6);
    width: 100%;
    border-radius: 0px !important;
    transition: .2s linear;
}

.list-style3 {
    list-style: none;
    padding-inline-start: 0;
}

.list-style3 li {
    border-bottom: 1px solid #fff3;
}

.list-style3 li:first-child a {
    padding-top: 0;
}

.list-style3 li a {
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding: 18px 0;
    position: relative;
    text-decoration: none;
    text-transform: capitalize;
    transition: all .3s ease 0s;
    z-index: 1;
}

.list-style3 li a:after {
    border-radius: 50%;
    color: #44cadf9c;
    content: "\25BA";
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    transform: scale(0, 0) translateX(10px) !important;
    transition: all .5s ease;
}