.toggle-2,
.toggle,
[id^=drop] {
    display: none;
}

.nav-link:focus, .nav-link:hover {
    color: var(--bs-color-accent50);
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--bs-color-accent100) !important;
}

.nav-link:hover {
    opacity: .7;
}

.dropdown-item {
    padding: 10px;
    font-weight: 500;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: var(--bs-color-accent50);
    background-color: unset;
}

/* Giving a background-color to the nav container. */
.navbar {
    background-color: var(--bs-color-primary100) !important;
    /* border-bottom: 1px solid #dee2e6 !important; */
    padding: 5px;
    z-index: 9999;
    transition: background-color 0.3s ease-in-out;
}

.navbar.navbar-shrink {
    background-color: var(--bs-color-primary100) !important;
}

.navbar.navbar-shrink .nav-link {
    color: #3a8f96;
}

.navbar-toggler {
    border: none;
    background: transparent !important;
}

.navbar-toggler:hover {
    background: transparent !important;
}

.navbar-toggler .icon-bar {
    width: 22px;
    height: 2px;
    display: block;
    border-radius: 1px;
    margin-top: 4px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background-color: white;
}

/* ANIMATED X */
.navbar-toggler.x .icon-bar:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 10% 10%;
    -ms-transform-origin: 10% 10%;
    transform-origin: 10% 10%;
}

.navbar-toggler.x .icon-bar:nth-of-type(2) {
    opacity: 0;
    filter: alpha(opacity=0);
}

.navbar-toggler.x .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 10% 90%;
    -ms-transform-origin: 10% 90%;
    transform-origin: 10% 90%;
}

/* ANIMATED X COLLAPSED */
.navbar-toggler.x.collapsed .icon-bar:nth-of-type(1) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}

.navbar-toggler.x.collapsed .icon-bar:nth-of-type(2) {
    opacity: 1;
    filter: alpha(opacity=100);
}

.navbar-toggler.x.collapsed .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}


.navbar-header {
    float: none;
}

.navbar-toggler {
    display: block;
}

/* .navbar-collapse {
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
} */

/* .navbar-collapse.collapse {
  display: none !important;
} */

.navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
}

.navbar-nav>li {
    float: none;
}

.navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
}

.navbar-collapse.collapse.in {
    display: block !important;
}

.navbar-brand {
    font-size: 14px;
}

.navbar-brand .logo {
    overflow: hidden;
}

.navbar-brand .logo img {
    transform: scale(1.8);
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: unset !important;
}

/* 
.collapse {
  background-color: #222222;
} */
/* .collapse:not(.show) {
  display: block;
  height: 0px;
  overflow: hidden;
  
} */

.navbar>.container-xxl:has(div.show) {}

nav {
    margin: 0;
    padding: 0;
}

#logo img {
    width: 80px;
    margin-left: 1em;
    transform: scale(1.2);
    /* transform: scale(2); */
}

#logo a {
    float: left;
    display: initial;
    font-weight: 700;
    font-size: 35px;
    max-width: 35px;
    letter-spacing: 1px;
    color: #000;
    padding: 0;
}


/* Since we'll have the "ul li" "float:left"
* we need to add a clear after the container. */

nav:after {
    content: "";
    display: table;
    clear: both;
}


/* Removing padding, margin and "list-style" from the "ul",
* and adding "position:reltive" */

nav ul {
    float: right;
    padding: 1em;
    margin: 0;
    list-style: none;
    position: relative;
}


/* Positioning the navigation items inline */

nav ul li {
    margin: 0px;
    display: inline-block;
    float: left;
}


/* Styling the links */

nav ul li a {
    color: #000;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 2px;
    background: transparent;
    padding: 7px 28px;
    display: inline-block;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
}

nav ul li ul li:hover {
    background: #f8f9fa;
}

.menu li .drop-text:hover {
    color: #000;
    border: none;
}


/* Background color change on Hover */


/*.menu li.active a,.menu li a:hover{border-bottom: 1px solid #d4d4d4;}*/

.menu li a:hover {
    opacity: 0.8;
    color: #000;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
}


/* Hide Dropdowns by Default
* and giving it a position of absolute */

nav ul ul {
    display: none;
    position: absolute;
    top: 33px;
    background: #000;
    padding: 10px;
    border-radius: 4px;
    box-shadow: -1px 1px 5px black;
}


/* Display Dropdowns on Hover */

nav ul li:hover>ul {
    display: inherit;
}


/* Fisrt Tier Dropdown */

nav ul ul li {
    width: 170px;
    float: none;
    display: list-item;
    position: relative;
}

nav ul ul li a {
    color: #fff;
    padding: 5px 10px;
    display: block;
    background: transparent;
}


/* Second, Third and more Tiers	
* We move the 2nd and 3rd etc tier dropdowns to the left
* by the amount of the width of the first tier.
*/

nav ul ul ul li {
    position: relative;
    top: -60px;
    /* has to be the same number as the "width" of "nav ul ul li" */
    left: 170px;
}


/* Change ' +' in order to change the Dropdown symbol */

li>a:only-child:after {
    content: '';
}

a.reqe-button {
    border: 2px solid rgba(255, 255, 255, 0.35);
    border-radius: 4px;
}


/* Media Queries
--------------------------------------------- */

@media(max-width: 800px) {
    nav a {
        font-size: 13px;
    }
}

@media all and (max-width: 767px) {
    #logo {
        display: block;
        padding: 0;
        width: 100%;
        text-align: center;
        float: none;
    }

    nav {
        margin: 0;
    }

    /* Hide the navigation menu by default */
    /* Also hide the  */
    .toggle+a,
    .menu {
        display: none;
    }

    /* Stylinf the toggle lable */
    .toggle {
        display: block;
        padding: 6px 17px;
        font-size: 14px;
        text-decoration: none;
        border: none;
        float: right;
        background-color: #3a8f96;
        color: #fff;
        border-radius: 50px;
        cursor: pointer;
    }

    .menu .toggle {
        float: none;
        text-align: center;
        margin: auto;
        max-width: 138px;
        padding: 10px 18px;
        font-weight: normal;
        font-size: 15px;
        letter-spacing: 1px;
        transition: 0.5s all;
        -webkit-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -o-transition: 0.5s all;
        -ms-transition: 0.5s all;
    }

    .menu .toggle:hover {
        color: #000;
        opacity: .8;
        transition: 0.5s all;
        -webkit-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -o-transition: 0.5s all;
        -ms-transition: 0.5s all;
    }

    .toggle:hover {
        color: #000;
        transition: 0.5s all;
        -webkit-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -o-transition: 0.5s all;
        -ms-transition: 0.5s all;
    }

    label.toggle.toogle-2 {
        background: transparent;
        text-transform: capitalize;
        font-size: 14px;
        color: #000;
    }

    /* Display Dropdown when clicked on Parent Lable */
    [id^=drop]:checked+ul {
        display: block;
        background: #3a8f96;
        padding: 15px 0;
        text-align: center;
        width: 100%;
    }

    /* Change menu item's width to 100% */
    nav ul li {
        display: block;
        width: 100%;
        padding: 5px 0;
    }

    nav ul ul .toggle,
    nav ul ul a {
        padding: 0 40px;
    }

    nav ul ul ul a {
        padding: 0 80px;
    }

    nav a:hover,
    nav ul ul ul a {
        background-color: transparent;
    }

    nav ul li ul li .toggle,
    nav ul ul a,
    nav ul ul ul a {
        padding: 14px 20px;
        color: #FFF;
        font-size: 15px;
    }

    /* 
nav ul li ul li .toggle,
nav ul ul a {
background-color: #212121;
} */
    /* Hide Dropdowns by Default */
    nav ul ul {
        float: none;
        position: static;
        color: #ffffff;
        /* has to be the same number as the "line-height" of "nav a" */
    }

    /* Hide menus on hover */
    nav ul ul li:hover>ul,
    nav ul li:hover>ul {
        display: none;
    }

    /* Fisrt Tier Dropdown */
    nav ul ul li {
        display: block;
        width: 100%;
        padding: 0;
    }

    nav ul ul ul li {
        position: static;
        /* has to be the same number as the "width" of "nav ul ul li" */
    }

    nav a {
        color: #000;
        font-size: 15px;
    }

    .menu li.active a,
    .menu li a:hover {
        background: transparent;
    }
}

@media all and (max-width: 568px) {

    nav a,
    .menu .toggle,
    nav ul li ul li .toggle,
    nav ul ul a,
    nav ul ul ul a {
        font-size: 14px;
    }
}

@media all and (max-width: 320px) {

    nav a,
    .menu .toggle,
    nav ul li ul li .toggle,
    nav ul ul a,
    nav ul ul ul a {
        font-size: 13px;
    }
}


.dropdown-item:focus, .dropdown-item:hover {
    color: var(--bs-color-accent50);
    background-color: unset;
}

.nav-link {
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    padding: 1em;
    /* margin-left: 20px; */
    font-weight: 600;
    letter-spacing: 1px;
}

.nav-link:focus, .nav-link:hover {
    color: var(--bs-color-accent50);
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--bs-color-accent100) !important;
}

.nav-link:hover {
    opacity: .7;
}

@media (min-width: 992px) {
    .navbar {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: none;
        background-color: transparent !important;
        transition: background-color 0.3s ease-in-out;
    }

    .navbar-brand {
        padding: 0.5rem 0;
        color: rgba(255, 255, 255, 0.5);
    }

    .nav-link {
        transition: none;
        padding: 2rem 1.5rem !important;
        /* color: #113f4e; */
        color: #fff;
        /* color: rgba(255, 255, 255, 0.5); */
    }

    #logo img {
        filter: brightness(0) saturate(100%) invert(98%) sepia(4%) saturate(847%) hue-rotate(256deg) brightness(120%) contrast(100%);
        /* filter: brightness(0) saturate(100%) invert(17%) sepia(91%) saturate(401%) hue-rotate(148deg) brightness(97%) contrast(94%); */
    }

    .navbar-shrink #logo img {
        filter: unset;
    }

    .nav-link:active {
        color: #fff;
    }

    .nav-link:hover {
        color: #fff;
    }


    .navbar-shrink {
        background-color: #fff;
    }



    .navbar-shrink .navbar-brand {
        color: #000;
    }

    .navbar-shrink .nav-link {
        padding: 1.5rem 1.5rem 1.25rem;
        border-bottom: 0.25rem solid transparent;
    }

    .navbar-shrink .nav-link:hover {
        color: #64a19d;
    }

    .navbar-shrink .nav-link:active {
        color: #467370;
    }

    .navbar-shrink .nav-link.active {
        color: #64a19d;
        outline: none;
        border-bottom: 0.25rem solid #64a19d;
    }
}