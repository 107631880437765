.sent-error{
    background: #8f3730;
    position: fixed;
    z-index: 999999;
    letter-spacing: 1px;
    top: 20px;
    right: 0;
    left: 0;
    padding: 10px 10% 10px 10%;
    color: white;
}

.sent-success{
    background: #215924ba;
    position: fixed;
    letter-spacing: 1px;
    z-index: 999999;
    top: 20px;
    right: 0;
    left: 0;
    padding: 10px 10% 10px 10%;
    color: white;
}