.why-choose-us {
    background-color: #f8f9fa;
}

/* .why-choose-us h2 {
    font-size: 2rem;
    font-weight: 700;
    color: #333;
} */

.benefit-item {
    border-radius: 8px;

    transition: transform 0.3s ease, box-shadow 0.3s ease;
}



.benefit-item h3 {
    font-size: 1.25rem;
    color: #000000;
    font-weight: 500;
    margin-top: 15px;
}

.benefit-item p {
    font-size: 0.9rem;
    color: #666;
}

.benefit-item .icon {
    max-width: 30%;
    margin: 0 auto;
}


@media screen and (min-width: 576px){
    .benefit-item p {
        min-height: 240px;
    }
    
}

@media screen and (min-width: 992px){
    .benefit-item p {
        min-height: 200px;
    }

    .benefit-item:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
    
}