.navbar.v2.navbar-shrink {
    background-color: #3e4347 !important;
}

.navbar.v2 .nav-link {
    color: var(--bs-color-light50) !important;
}

.navbar.v2 #logo img {
    filter: unset  !important;   
}