.service-container {
    padding: 70px 0;
    overflow: hidden;
    background: #fff;
    position: relative;
}

@media (min-width: 992px) {
    .service-container {
        padding: 90px 0;
    }
}

@media (min-width: 1200px) {
    .service-container {
        padding: 120px 0;
    }
}

.service-details-sidebar .widget {
    position: relative;
    margin-bottom: 30px;
    background-color: #374a56;
    border: 1px solid #374a56;
    padding: 35px 30px;
    border-radius: 10px;
}

.service-details-sidebar .side-bar h6 {
    position: relative;
    display: inline-block;
    z-index: 1;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 1em;
    color: #1191a6;
}

/* .service-details-sidebar .side-bar h6:before {
    content: '';
    background: #44cadf9c;
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: 6px;
    z-index: -1; */
/* } */

.list-style1 {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
}

.list-style1 li {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 15px;
    /* display: flex; */
}

.list-style1 li p{
    font-weight: 300;
}

.list-style1 li:before {
    content: '\279C';
    font-size: 20px;
    font-weight: 800;
    color: #374a56;
    font-size: 16px;
    vertical-align: middle;
    padding-right: 18px;
}

.list-style3 li.active a, .list-style3 li a:hover {
    color: #44cadf9c;
}

.list-style3 {
    list-style: none;
    padding-inline-start: 0;
}

.list-style3 li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.list-style3 li:first-child a {
    padding-top: 0;
}

.list-style3 li a {
    text-decoration: none;
    position: relative;
    display: block;
    text-transform: capitalize;
    color: #fff;
    padding: 18px 0;
    font-weight: 500;
    z-index: 1;
    font-size: 16px;
    transition: all 0.3s ease 0s;
}

.list-style3 li.active a:after, .list-style3 li a:hover:after {
    transform: scale(1, 1) translateX(10px);
    color: #44cadf9c;
}

.list-style3 li a:after {


    position: absolute;
    content: '\25BA';
    font-weight: 600;
    /* top: 16px; */
    font-size: 18px;
    /* right: 15px; */
    color: #44cadf9c;
    border-radius: 50%;
    transition: all 500ms ease;
    transform: scale(0, 0) translateX(10px);
}

.widget-address>div:not(.section-title) h6, .widget-address p {
    text-align: left;
    /* padding-inline-start: 3em; */
}

.widget-address a{
    text-decoration: none;
    color: #44cadf;
}

.widget-address .fa {
    font-size: calc(1.275rem + .3vw) !important;
}

.service-details h3,.service-details h4  {
    font-weight: 600;
    color: #142F43;
}

.service-image {
    border-radius: 10px;
    overflow: hidden;
}

.service-image img {
    width: 100%;
    /* height: auto; */
    vertical-align: top;
}

.service-image::after {
    content: '';
    position: absolute;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    border-radius: 10px;
    overflow: hidden;
    top: 0;
    left: calc(var(--bs-gutter-x)* .5);
    right: calc(var(--bs-gutter-x)* .5);
    height: 100%;
    display: block;
    color: #FFF;
    box-shadow: inset 0 0 96px #000000e0;
}