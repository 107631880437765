.call-to-action {
    position: fixed;
    bottom: 14px;
    right: 14px;
    height: 54px;
    width: 54px;
    /* background: red; */
    z-index: 100;
}

.call-to-action a{
    font-size: 30px;
    color: white;
    background-color: #138fa6;
    padding: 10px 15px;
    border-radius: 30px;
    box-shadow: 1px 5px 9px #12414f;
}

.call-to-action a:hover{
    background-color: #12414f !important;
    transition: .2s linear;
}