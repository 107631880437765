.cta-area {
    height: 380px;
    background-image: url(../../../public/assets/images/handshaking.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffffde;
    text-shadow: 0px 2px 4px #000000de;

}

/* .cta-area::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 20px; 
    display: block;
    color: #FFF;
    box-shadow: inset 0 0 96px #1d4b5a78;
    background-image: linear-gradient(120deg, #168fa700, #168fa700 45%, #5f9fad99 45%, #5f9fad94 75%, rgb(5 53 69 / 62%) 75%, rgb(5 53 69));
}
 */

.cta-block__light-text {
    font-size: 36px;
    font-weight: 400;
    line-height: 1;
}

.cta-block__light-text span {
    font-size: 48px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
}

.cta-block__semi-bold-text--medium {
    font-size: 28px;
}

.cta-block__bold-text {
    font-size: 38px;
    font-weight: 700;
    line-height: 1;
    text-decoration: unset;
    color: #ffffffde !important;

}

.cta-block__bold-text:hover {
    color: #577587db !important;
}

.cta-wrapper {
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
    z-index: 100;
    padding-top: 50px;
    box-shadow: inset 0 0 96px #1d4b5a78;
    background-image: linear-gradient(120deg, #168fa700, #168fa700 45%, #5f9fad99 45%, #5f9fad94 75%, rgb(5 53 69 / 62%) 75%, rgb(5 53 69));
    /* background-color: rgb(19 143 166 / 29%); */
    /* background-color: rgba(43, 45, 55, 0.9); */
}

.funfacts-elements-wrapper {
    padding: 0px !important;
    margin: -122px auto 0px auto;
    width: 90%;
    border-radius: 30px;
    background: transparent;
}

.funfacts-elements {
    padding: 50px 0px !important;

    /* background-image: url(../../../public/assets/images/ruler-black.webp); */
    background-position: bottom;
    /* background-color: #577587db; */
    background-repeat: repeat-x;
}

.funfacts-elements .row{
    --bs-gutter-x : unset;
}

.funfacts-elements h6 {
    font-size: 36px;
    color: #4bacbc !important;
    font-weight: 800;
    letter-spacing: 1px;
}

.funfacts-elements h3 {
    color: #ffffffc2;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2px;
}

.funfacts-elements .text-center{
    padding: 1.5em .5em;
    margin: .1em;
    background: #12414f;
    border-radius: 20px;
    min-height: 140px;
}



@media (max-width: 450px) {
    .funfacts-elements h6 {
        font-size: 36px;
    }
    
    .funfacts-elements h3 {
        color: #ffffffc2;
        font-size: 12px;
    }
}

/* @media (max-width:576) {
    .funfacts-elements h6 {
        font-size: 36px;
    }
    
    .funfacts-elements h3 {
        font-size: 16px;
    }
} */