.process-image-container .container {
    margin-bottom: -9%;
    max-width: 100% !important;
}

.process-image {
    z-index: 91;
    height: 480px;
    background-image: url(../../../public/assets/images/handshaking.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    
}

.process-image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 20px; */
    display: block;
    color: #FFF;
    box-shadow: inset 0 0 96px #1d4b5a78;
    background-image: linear-gradient(120deg, #168fa700, #168fa700 45%, #5f9fad99 45%, #5f9fad94 75%, rgb(5 53 69 / 62%) 75%, rgb(5 53 69));
}

.process-image span {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 100;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 7px;
    color: white;
    text-shadow: 1px 2px 3px black;
}

@media (min-width:768px) {
    .process-image span {
        font-size: 60px;
    }
}

.process-image img {
    border-radius: 20px;
    max-width: 100%;
}

.cover-background {
    position: relative !important;
    background-size: cover !important;
    overflow: hidden !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.process-wrapper {
    width: 167px;
    height: 167px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    margin-bottom: 45px;
}

.process-wrapper .process-count {
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: 54px;
    top: 30px;
    z-index: -1;
}

.process-wrapper .process-count span {
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
}

.process-wrapper .process-icon {
    width: 100px;
    height: 100px;
    background: #4bacbc;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    left: 34px;
    bottom: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.process-steps-container {
    padding-top: 200px;
    padding-bottom: 50px;
}

@media screen and (min-width: 576px) {
    .mt-sm-2-9, .my-sm-2-9 {
        margin-top: 2.9rem;
    }
}