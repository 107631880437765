
.list-style4 {
    position: absolute;
    overflow-y: scroll;
    width: 250px;
    max-height: 250px;
    list-style: none;
    top:5em;
   
}

.list-style4::-webkit-scrollbar {
    margin-left: 2px;
    width: 2px;
}

.list-style4::-webkit-scrollbar-track {
    background-color: rgb(199, 192, 192);
}

.list-style4::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.list-style4 li {
    font-size: 12px;
    padding: 10px 0;
   /* box-shadow: 1px 1px 1px red; */
    text-align: start;
}

.list-style4 li:after {
    content: '\00a0';
    background-image: linear-gradient(90deg, transparent 10% ,#ffffff8c 50%, transparent 90%);
    background-size: 100% 1px;
    background-repeat: no-repeat;
    float: left;
    width: 100%;
}

.list-style4 li a {
    color: white !important;
    text-decoration: unset;
}

@media(min-width: 992px) {
    .list-style4 {
        top:5em;
        max-height: 250px;
    }
}